import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import LocationItem from '~2-components/LocationItem/LocationItem';
import LocationList from '~2-components/LocationList/LocationList';
import { ourLocationsData } from "~data/ourLocationsData";
import { useStaticQuery, graphql } from "gatsby";

const locationItemEg = `import LocationList from '~2-components/LocationList/LocationList';

<LocationList
    locations=[{}, {}]
/>
`;

const locationListEg = `import LocationItem from '~2-components/LocationItem/LocationItem';

<LocationItem
    city='' // Required
    street='' // If blank, then displays 'by appointment only' and Google directions unavailable
    state='' // Required
    postcode='' // Required
    phone='' // Required
    fax='' // Required
/>
`;

const SgLocationItem = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const locationLists = useStaticQuery(
        graphql`
            query SGllLocationLists {
                allKontentItemLocationlist {
                    nodes {
                        system {
                            id
                            type
                        }
                    }
                }
            }
    `);

    const allLocationLists = locationLists.allKontentItemLocationlist.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Location Items</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={locationItemEg}
                    />
                    <CodeBlock
                        code={locationListEg}
                    />

                    <h3>Example location item</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <LocationItem
                        city='Melbourne'
                        street='Level 8, 555 Bourke Street'
                        state='VIC'
                        postcode='3000'
                        phone='(03) 9321 9988'
                        fax='(03) 9933 2039'
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Location list</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <LocationList
                        locations={ourLocationsData}
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent Location Lists</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allLocationLists.map((item, i) => {
                        return (
                            <LocationList
                                key={i}
                                {...item}
                            />
                        );
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgLocationItem;
