export const ourLocationsData = [
    {
        city: 'Melbourne CBD',
        street: 'Level 8, 555 Bourke Street',
        state: 'VIC',
        postcode: '3000',
        phone: '(03) 9321 9988',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Ballarat',
        street: '',
        state: 'VIC',
        postcode: '3111',
        phone: '(03) 9321 9988',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Bendigo',
        street: '',
        state: 'VIC',
        postcode: '3000',
        phone: '(03) 9321 9988',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Epping',
        street: 'Shop 110B, Epping Plaza (cnr High and Cooper Street)',
        state: 'VIC',
        postcode: '3076',
        phone: '(03) 9321 9786',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Geelong',
        street: '',
        state: 'VIC',
        postcode: '3000',
        phone: '(03) 9321 9988',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Melton',
        street: '43 Wallace Square',
        state: 'VIC',
        postcode: '3337',
        phone: '(03) 9321 9777',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Moe',
        street: 'Level 1, 18-20 Kirk Street,',
        state: 'VIC',
        postcode: '3825',
        phone: '(03) 9321 9879',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Springvale',
        street: '369C Springvale Road,',
        state: 'VIC',
        postcode: '3171',
        phone: '(03) 9321 9886',
        fax: '(03) 9321 9900'
    },
    {
        city: 'Wangaratta',
        street: '26B Reid Street,',
        state: 'VIC',
        postcode: '3677',
        phone: '(03) 9321 9779',
        fax: '(03) 9321 9900'
    }
];
